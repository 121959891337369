<template>
  <div class="position-relative">
    <div v-if="p_orders.length > 0">
      <b-row>
        <b-col md="6">

        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="7">
              <b-form-group   label-cols-sm="2" label-align-sm="right" label-size="md"
                label-for="filterInput">
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group   label-cols-sm="8" label-align-sm="right" label-size="md"
                label-for="perPageInput">
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                  @change="savePerPage(perPage)" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>


      <!-- <span>Total Rows : <b>{{totalRows}}</b></span> -->
      <b-table :items="p_orders" :fields="fields" striped responsive id="purchasing-table" small class="hide-scroll-bar"
        :per-page="perPage" :current-page="currentPage" :filter="filter" :filter-included-fields="filterOn"
        @filtered="onFiltered">
        <!-- <template #cell(select)="data">
          <b-form-checkbox
              v-model="data.item.checkboxStatus"
              v-on:change="selectRows(data.item.id)"          
              class="custom-control-primary"
          >
          </b-form-checkbox>
        </template> -->

        <!-- <template #cell(purchase_order_#)="data">

          <span v-if="String(data.item.id).length < 5">
            {{ data.item.purchase_order_num }}-{{ pad('0000', data.item.id, true) }}
          </span>
          <span v-else>
            {{ data.item.purchase_order_num }}-{{ data.item.id }}
          </span>

        </template> -->

        <template #cell(purchase_order_date)="data">
          <span>{{ data.item.created_at | formatDate }}</span>
        </template>

        <template #cell(order_status)="data">
          <b-row>
            <b-col md="6">
              <b-badge pill :variant="data.item.variant">
                {{ data.item.status }}
              </b-badge>
            </b-col>

            <b-col md="6" v-if="data.item.has_invoice">

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-primary" class="btn-icon mb-2" size="sm"
                v-b-tooltip.hover.v-primary title="Download Invoice"
                @click="handleInvoiceDownload(data.item.pdf_invoice.invoice_title)">
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </b-col>

            <b-col md="6" v-else>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-primary" class="btn-icon mb-2" size="sm"
                v-b-tooltip.hover.v-primary title="Upload Invoice" @click="handleInvoiceUpload(data.item.id)">
                <feather-icon icon="UploadIcon" />
              </b-button>
              <input type="file" class="hidden" ref="uploadInvoice" @change="submitPdf" accept="application/pdf">
            </b-col>
          </b-row>

          <!-- <b-form-file
            v-if="show_invoice_upload"
            id="extension"
            accept=".pdf"
            v-model="invoice_pdf"
            class="mt-1"
            size="sm"
            :state="Boolean(invoice_pdf)"
          /> -->
        </template>

        <!-- <template #cell(supplier_name)="data">
          <span>{{ data.item.supplier.super_supp.name }}</span>
        </template> -->


        <template #cell(image)="data">
          <div v-for="product in data.item.purchase_order_products" :key="product.id">
            <div v-lazy-container="{
              selector: 'img',
              error:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
              loading:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            }">
              <!-- <img :src="product['products'].productImageUrl" width="100px"> -->
              <br>
            </div>

          </div>
        </template>

        <template #cell(sku)="data">
          <div v-for="product in data.item.purchase_order_products" :key="product.id"><br>
            <!-- {{ product['products'].sku }} <b>x</b> {{ product.product_quantity }} -->
          </div>
        </template>

        <template #cell(title)="data">
          <div v-for="product in data.item.purchase_order_products" :key="product.id"><br>
            <!-- {{ product['products'].product_name }} -->
          </div>
        </template>

      </b-table>

      <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" first-number last-number
        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>

    </div>

    <div v-else class="justify-center">
      <h3>No Purchase Orders</h3>
    </div>

    <b-overlay :show="show" no-wrap spinner-variant="primary" />

  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BPagination,
  BFormRadio, VBToggle, BDropdown,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BImg, BOverlay,
  BBadge, BFormFile,
  VBTooltip
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormRadio,
    VBToggle,
    BDropdown,
    ToastificationContent,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BImg,
    BOverlay,
    BBadge,
    BFormFile,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      pageOptions: [25, 50, 100, 200],
      perPage: '',
      currentPage: 1,
      totalRows: 1,
      filter: null,
      filterOn: [],
      fields: ['purchase_order_num', 'purchase_order_date', { key: 'order_status', label: 'Order Status' }, 'supplier_id', 'image', 'sku', 'title'],
      p_orders: [],
      show: false,
      statusOptions: ['Received', 'Waiting'],
      formData: {},
      poId: null

    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY , hh:mm:ss')
      }
    }
  },
  created() {
    this.perPage = this.getPerPage() ? this.getPerPage() : '25'
    this.get()
  },
  methods: {

    async get() {

      this.show = true
      try {
        await axios.get(process.env.VUE_APP_API + '/get-purchaseorders')
          .then(response => {
            this.p_orders = response['data']
            this.totalRows = this.p_orders.length
            this.show = false
          })
      }
      catch (error) {
        this.show = false
        console.log(error)
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    pad(pad, str, padLeft) {

      if (typeof str === 'undefined')
        return pad;
      if (padLeft) {
        return (pad + str).slice(-pad.length);
      } else {
        return (str + pad).substring(0, pad.length);
      }
    },
    selectRows(id) {
      console.log(data.item.purchase_order_products);
      var status = false

      for (var i = 0; i < this.selectedRows.length; i++) {
        if (this.selectedRows[i] == id) {
          status = true
        }
      }
      if (status) {
        const index = this.selectedRows.indexOf(id);
        if (index > -1) {
          this.selectedRows.splice(index, 1);
        }
      }
      else {
        this.selectedRows.push(id)
      }
    },
    updatePOstatus(id) {

      this.show = true

      this.formData['status'] = 'Received'
      this.formData['variant'] = 'success'
      this.formData['id'] = id
      axios({
        method: 'post',
        url: process.env.VUE_APP_API + '/update-purchase-order-status',
        data: this.formData
      }).then(res => {
        if (res.data.success) {
          this.get()
          this.show = false
        }
      }
      )

      for (let index = 0; index < this.p_orders.length; index++) {
        const element = this.p_orders[index];
        element.checkboxStatus = false
      }
      this.selectedRows = []
      this.formData = {}
      this.statusType = ''

      // else{
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'No row(s) selected',
      //       icon: 'AlertOctagonIcon',
      //       text: 'Select row(s)',
      //       variant:'danger',
      //     },     
      //   },
      //   {
      //     position:'top-center'
      //   })
      // }
    },
    handleInvoiceUpload(po_id) {
      this.$refs.uploadInvoice.click()
      this.poId = po_id
    },
    submitPdf() {
      var file = this.$refs.uploadInvoice.files[0]

      let formData = new FormData();
      formData.append('file', file);
      formData.append('po_id', this.poId);

      axios.post(process.env.VUE_APP_API + '/pdf-invoice', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          if (res.data.success) {
            this.updatePOstatus(this.poId)
          }
        })
    },
    handleInvoiceDownload(invoiceTitle) {
      try {
        window.open(process.env.VUE_APP_API + '/pdf-invoice/' + `${invoiceTitle}`)
        // axios.get(process.env.VUE_APP_API+'/pdf-invoice/'+`${invoiceTitle}`)  
        // .then(response => {
        //   if(!response.data.success){
        //     this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Error',
        //       icon: 'AlertOctagonIcon',
        //       text: 'No Invoice for this purchase order',
        //       variant:'danger',
        //     },     
        //   },
        //   {
        //     position:'top-right'
        //   })
        //   }
        //   else{
        //     window.open(process.env.VUE_APP_API+'/pdf-invoice/'+`${invoiceTitle}`)
        //   }
        // })
      }
      catch (error) {
        console.log(error)
      }
    }

  }

}
</script>

<style scoped>
.bg {
  border: solid black 2px;
}
</style>


